import ManagerWS from './managerWS';
import { getUrlParam } from '../utils/url';
import eWSEventId from './eWSEventId';
import GameModel from '../models/GameModel';
import GlobalDispatcher from '../events/GlobalDispatcher';

const initManagerParams = {
  developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=fighter&no_redirect&no_op_launcher',
  baseData: {
    ver: '1.0'
  }
};

export const requestAuth = async () => {
  window.OPWrapperService.serverManager.initManagerGameRequests(initManagerParams);
  await window.OPWrapperService.serverManager.sendGameInitRequest();
  console.log('GAME INITED');

  const sessionHash = getUrlParam('sid');
  const sessionId = getUrlParam('gid');
  return await ManagerWS.send({ id: 'auth', sessionId, sessionHash });
};

export const requestReAuth = async () => {
  const sessionHash = getUrlParam('sid');
  const sessionId = getUrlParam('gid');
  return await ManagerWS.send({ id: 'auth', sessionId, sessionHash });
};

export const makeBet = (amount, betModel) => {
  const isFreeBets = window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isActive || window.OPWrapperService.freeBetsController.data.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;
  if (GameModel.balance < amount && !isFreeBets) {
    window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
    return;
  }

  OPWrapperService.ControllerStatistic.bet = amount;
  OPWrapperService.realityCheck.blockedRealityCheck = true;

  betModel.waitingAuth = !!ManagerWS.ws.closed;
  betModel.waitingForResponse = true;
  let params = { amount, id: 'bet', jackpot: window.forceJackpot, isFreeBets };
  if (betModel.autoTake) params.autoTake = betModel.takeCoef.toFixed(2);
  ManagerWS.send(params)
    .then((response) => {
      betModel.waitingAuth = false;
      betModel.waitingForResponse = false;
      betModel.setBetInfo(response.betId, response.roundId, response.amount);

      if (isFreeBets) {
        window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
      } else {
        window.OPWrapperService.ControllerStatistic.increaseSpinCount();
      }
    })
    .catch(() => {
      betModel.waitingForResponse = false;
      betModel.waitingAuth = false;
    });
};

export const takeBet = (betId, betModel) => {
  betModel.waitingForResponse = true;
  return new Promise((resolve, reject) => {
    ManagerWS.send({ betId, id: 'take' })
      .then((data) => {
          betModel.waitingForResponse = false;
          betModel.setBetInfo(null, null, window.OPWrapperService.freeBetsController.isFreeBets && window.OPWrapperService.freeBetsController.data.bet);
          OPWrapperService.ControllerStatistic.win = data.winAmount;
          if (!GameModel.bets.find(betModel => !!betModel.betID)) OPWrapperService.realityCheck.blockedRealityCheck = false;
          resolve(data);
        },
        (data) => {
          betModel.waitingForResponse = false;
          betModel.setBetInfo(null, null, window.OPWrapperService.freeBetsController.isFreeBets && window.OPWrapperService.freeBetsController.data.bet);
          reject(data);
        });
  });
};

export const cancelBet = (betId, betModel) => {
  betModel.waitingAuth = !!ManagerWS.ws.closed;
  betModel.waitingForResponse = true;
  betModel.waitingForCancelResponse = true;
  ManagerWS.send({ betId, id: 'cancel' })
    .then(() => {
      OPWrapperService.ControllerStatistic.canceledBet = betModel.betAmount;
      betModel.waitingAuth = false;
      betModel.waitingForCancelResponse = false;
      betModel.waitingForResponse = false;
      betModel.setBetInfo(null, null, window.OPWrapperService.freeBetsController.isFreeBets && window.OPWrapperService.freeBetsController.data.bet);

      if (window.OPWrapperService.freeBetsController.isFreeBets) {
        window.OPWrapperService.ControllerStatistic.decreaseFreeBetsCount();
      } else {
        window.OPWrapperService.ControllerStatistic.decreaseSpinCount();
      }
      if (!GameModel.bets.find(betModel => !!betModel.betID)) OPWrapperService.realityCheck.blockedRealityCheck = false;
    });
};

export const sendEmoji = (type) => {
  ManagerWS.send({ id: eWSEventId.EWEI_EMOJI, emojiId: type });
};

const sendPing = () => {
  if (window.ping_off) return;
  ManagerWS.send({ id: eWSEventId.EWEI_PING });
};
GlobalDispatcher.add(eWSEventId.EWEI_PING, sendPing);
